import React, { Component } from "react";
import { Dropdown, Image } from "react-bootstrap";
import { Trans } from "react-i18next";
import { Link, Redirect } from "react-router-dom";
import session, { KEY } from "./Session";
import { AsyncStorage } from "AsyncStorage";
import "../dashboard//Dashboard.css";
var prevScrollpos = window.pageYOffset;
window.onscroll = function () {
  var currentScrollPos = window.pageYOffset;
  if (prevScrollpos > currentScrollPos) {
    document.getElementById("navbar").style.top = "0";
  } else {
    document.getElementById("navbar").style.top = "-70px";
  }
  prevScrollpos = currentScrollPos;
};
class NavbarPlain extends Component {
  toggleOffcanvas() {
    document.querySelector(".sidebar-offcanvas").classList.toggle("active");
  }
  toggleRightSidebar() {
    document.querySelector(".right-sidebar").classList.toggle("open");
  }

  componentDidMount() {
    document.body.classList.toggle("sidebar-icon-only");
  }

  render() {
    return (
      <nav
        style={{ background: "#0095DA",height:'64px' }}
        className="col-lg-12 col-12 p-lg-0 fixed-top d-flex flex-row"
      >
        <div className="navbar-menu-wrapper d-flex align-items-center justify-content-between">
          <Image
            className="navbar-nav navbar-nav-left navbar-toggler"
            src={require("../assets/logoend.png")}
          />
        </div>
      </nav>
    );
  }
}

export default NavbarPlain;
