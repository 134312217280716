import React, { Component } from "react";
import { Dropdown, Image } from "react-bootstrap";
import { Trans } from "react-i18next";
import { Link, Redirect } from "react-router-dom";
import session, { KEY } from "../shared/Session";
import { AsyncStorage } from "AsyncStorage";
import "../dashboard//Dashboard.css";
var prevScrollpos = window.pageYOffset;
window.onscroll = function () {
  var currentScrollPos = window.pageYOffset;
  if (prevScrollpos > currentScrollPos) {
    document.getElementById("navbar").style.top = "0";
  } else {
    document.getElementById("navbar").style.top = "-70px";
  }
  prevScrollpos = currentScrollPos;
};
class Navbar extends Component {
  toggleOffcanvas() {
    document.querySelector(".sidebar-offcanvas").classList.toggle("active");
  }
  toggleRightSidebar() {
    document.querySelector(".right-sidebar").classList.toggle("open");
  }

  componentDidMount() {
    document.body.classList.toggle("sidebar-icon-only");
  }

  render() {
    return (
      <nav id='navbar' style={{background:'#0095DA'}} className="navbar col-lg-12 col-12 p-lg-0 fixed-top d-flex flex-row">
        <div className="navbar-menu-wrapper d-flex align-items-center justify-content-between">
          <button
            className="navbar-toggler navbar-toggler align-self-center"
            type="button"
            onClick={() => document.body.classList.toggle("sidebar-icon-only")}
          >
            <i style={{ color: "white" }} className="mdi mdi-menu"></i>
          </button>
          <ul className="navbar-nav navbar-nav-left header-links align-self-center">
            <li style={{color:'white'}} className="nav-item font-weight-semibold d-none  d-md-flex"></li>
          </ul>

          <Image className="navbar-nav" src={require('../assets/logoend.png')} />

          <ul className="navbar-nav navbar-nav-right">


            <li className="nav-item  nav-profile border-0">

            </li>
          </ul>
          <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" onClick={this.toggleOffcanvas}>
            <span style={{color:'white'}} className="mdi mdi-menu"></span>
          </button>
        </div>
      </nav>
    );
  }
}

export default Navbar;
