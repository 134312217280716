import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Collapse, Form } from 'react-bootstrap';
import { Dropdown } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import { AsyncStorage } from 'AsyncStorage';
import { KEY } from './Session';
import Image from 'react-bootstrap/Image'
import { SECONDARY_COLOR2 } from '../constants/color';
class Sidebar extends Component {
  state = {
    name: ''
  };

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true });
    } else {
      Object.keys(this.state).forEach(i => {
        this.setState({ [i]: false });
      });
      this.setState({ [menuState]: true });
    }
  }
  componentDidMount() {

  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();

    }


  }

  onRouteChanged() {
    document.querySelector('#sidebar').classList.remove('active');
    Object.keys(this.state).forEach(i => {
      this.setState({ [i]: false });
    });

    const dropdownPaths = [
      { path: '/apps', state: 'appsMenuOpen' },
      { path: '/basic-ui', state: 'basicUiMenuOpen' },
      { path: '/form-elements', state: 'formElementsMenuOpen' },
      { path: '/tables', state: 'tablesMenuOpen' },
      { path: '/icons', state: 'iconsMenuOpen' },
      { path: '/charts', state: 'chartsMenuOpen' },
      { path: '/user-pages', state: 'userPagesMenuOpen' },
      { path: '/error-pages', state: 'errorPagesMenuOpen' },
    ];

    dropdownPaths.forEach((obj => {
      if (this.isPathActive(obj.path)) {
        this.setState({ [obj.state]: true })
      }
    }));

  }
  render() {

    return (
      <nav style={{background: '#0095DA',paddingTop:'64px'}} className="sidebar sidebar-offcanvas" id="sidebar">
        <ul className="nav">
          <li className={this.isPathActive('/home') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/home">
              <i className="mdi mdi-home menu-icon"></i>
              <span className="menu-title"><Trans>Home</Trans></span>
            </Link>
          </li>

          <li className={this.isPathActive('/organisers') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/organisers">
              <i className="mdi mdi-account menu-icon"></i>
              <span className="menu-title"><Trans>Contest organiser portal</Trans></span>
            </Link>
          </li>

          <li className={this.isPathActive('/partners') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/partners">
              <i className="mdi mdi-account-multiple menu-icon"></i>
              <span className="menu-title"><Trans>Partners portal</Trans></span>
            </Link>
          </li>

          <li className={this.isPathActive('/faq') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/faq">
              <i className="mdi mdi-frequently-asked-questions menu-icon"></i>
              <span className="menu-title"><Trans>FAQ</Trans></span>
            </Link>
          </li>

          <li className={this.isPathActive('/contact_us') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/contact_us">
              <i className="mdi mdi-email menu-icon"></i>
              <span className="menu-title"><Trans>Contact Us</Trans></span>
            </Link>
          </li>


        </ul>
      </nav>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    this.onRouteChanged();
    // add className 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector('body');
    document.querySelectorAll('.sidebar .nav-item').forEach((el) => {

      el.addEventListener('mouseover', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
  
        }
      });
      el.addEventListener('mouseout', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
       
        }
      });
    });
  }

}

export default withRouter(Sidebar);