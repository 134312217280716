import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { SECONDARY_COLOR3 } from '../constants/color';
import '../dashboard/Dashboard.css';

class Footer extends Component {
  render() {
    return (
      <footer>
        <div className="FooterContainer">
          <div className='FooterSection'>
            <Link style={{ color: 'black', marginLeft: 16, marginRight: 16 }} to={{ pathname: "https://endresult.in/privacy_policy.pdf" }} target="_blank">
              Privacy Policy
            </Link>
            <Link style={{ color: 'black', marginLeft: 16, marginRight: 16 }} to={{ pathname: "https://endresult.in/terms_and_conditions.pdf" }} target="_blank">
              Terms and conditions
            </Link>
            <Link style={{ color: 'black', marginLeft: 16, marginRight: 16 }} to="/faq">
              FAQ
            </Link>
            <Link style={{ color: 'black', marginLeft: 16, marginRight: 16 }} to="/contact_us">
              Contact Us
            </Link>
          </div>
          <div className='FooterSectionSocial'>
            <Link style={{ color: 'black', marginLeft: 16, marginRight: 16 }} to={{ pathname: "https://www.facebook.com/109832101738342" }} target="_blank">
              <i style={{ fontSize: 25 }} className="mdi mdi-facebook menu-icon"></i>
            </Link>
            <Link style={{ color: 'black', marginLeft: 16, marginRight: 16 }} to={{ pathname: "https://www.instagram.com/endresult.in/?igshid=YmMyMTA2M2Y=" }} target="_blank">
              <i style={{ fontSize: 25 }} className="mdi mdi-instagram menu-icon"></i>
            </Link>
          </div>
          <div className='FooterSectionSocial'>
            <div className="d-sm-flex justify-content-center justify-content-sm-between py-2 w-100">
              <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © endresult 2020</span>
            </div>
          </div>
        </div>
      </footer >
    );
  }
}

export default Footer;