import React, { Component, Suspense, lazy } from 'react';
import { Switch, Route, Redirect, Router } from 'react-router-dom';
import Spinner from '../app/shared/Spinner';

const Dashboard = lazy(() => import('./dashboard/Dashboard'));
const Admin = lazy(() => import('./dashboard/Admin'));
const Partners = lazy(() => import('./dashboard/Partners'));
const Organisers = lazy(() => import('./dashboard/Organisers'));
const AdminDashboard = lazy(() => import('./dashboard/AdminDashboard'));
const AddPartners = lazy(() => import('./dashboard/AddPartners'));
const RegisterPartners = lazy(() => import('./dashboard/RegisterPartners'));
const ViewPartners = lazy(() => import('./dashboard/ViewPartners'));
const ChangePassword = lazy(() => import('./dashboard/ChangePassword'));
const EditPartners = lazy(() => import('./dashboard/EditPartners'));
const PartnersDashboard = lazy(() => import('./dashboard/PartnersDashboard'));
const PartnersProfile = lazy(() => import('./dashboard/PartnersProfile'));
const AddContest = lazy(() => import('./dashboard/AddContest'));
const AddContestTwo = lazy(() => import('./dashboard/AddContestTwo'));
const ViewContest = lazy(() => import('./dashboard/ViewContest'));
const ViewContestTwo = lazy(() => import('./dashboard/ViewContestTwo'));
const EditContest = lazy(() => import('./dashboard/EditContest'));
const EditContestTwo = lazy(() => import('./dashboard/EditContestTwo'));
const ContestRegister = lazy(() => import('./dashboard/ContestRegister'));

const AddContestPartner = lazy(() => import('./dashboard/AddContestPartner'));
const AddContestTwoPartner = lazy(() => import('./dashboard/AddContestTwoPartner'));
const ViewContestPartner = lazy(() => import('./dashboard/ViewContestPartner'));
const ViewContestTwoPartner = lazy(() => import('./dashboard/ViewContestTwoPartner'));
const EditContestPartner = lazy(() => import('./dashboard/EditContestPartner'));
const EditContestTwoPartner = lazy(() => import('./dashboard/EditContestTwoPartner'));
const ChangePasswordPartner = lazy(() => import('./dashboard/ChangePasswordPartner'));

const OrganiserDashboard = lazy(() => import('./dashboard/OrganiserDashboard'));
const ContestProfile = lazy(() => import('./dashboard/ContestProfile'));
const PrizeDetails = lazy(() => import('./dashboard/PrizeDetails'));

const ChangePasswordContest = lazy(() => import('./dashboard/ChangePasswordContest'));
const PaymentManagement = lazy(() => import('./dashboard/PaymentManagement'));
const AddContestFromList = lazy(() => import('./dashboard/AddContestFromList'));
const ViewContestFromList = lazy(() => import('./dashboard/ViewContestFromList'));
const EditContestFromList = lazy(() => import('./dashboard/EditContestFromList'));
const PrizeDetailsAdmin = lazy(() => import('./dashboard/PrizeDetailsAdmin'));
const PrizeDetailsTwoAdmin = lazy(() => import('./dashboard/PrizeDetailsTwoAdmin'));
const AddContestFromListPartner = lazy(() => import('./dashboard/AddContestFromListPartner'));
const ViewContestFromListPartner = lazy(() => import('./dashboard/ViewContestFromListPartner'));
const PrizeDetailsPartner = lazy(() => import('./dashboard/PrizeDetailsPartner'));
const ViewContestOrganiser = lazy(() => import('./dashboard/ViewContestOrganiser'));
const PrizeDetailsOrganiser = lazy(() => import('./dashboard/PrizeDetailsOrganiser'));

const EditContestFromListOrganiser = lazy(() => import('./dashboard/EditContestFromListOrganiser'));
const PrizeDetailsTwoOrganiser = lazy(() => import('./dashboard/PrizeDetailsTwoOrganiser'));
const AddContestFromListOrganiser = lazy(() => import('./dashboard/AddContestFromListOrganiser'));
const AddContestTwoOrganiser = lazy(() => import('./dashboard/AddContestTwoOrganiser'));
const ContactUs = lazy(() => import('./dashboard/ContactUs'));
const OrganisersForgotPassword = lazy(() => import('./dashboard/OrganisersForgotPassword'));
const PartnerForgotPassword = lazy(() => import('./dashboard/PartnerForgotPassword'));
const AdManagement = lazy(() => import('./dashboard/AdManagement'));
const AdManagement2 = lazy(() => import('./dashboard/AdManagement2'));
const AdCreate = lazy(() => import('./dashboard/AdCreate'));
const AdCreate2 = lazy(() => import('./dashboard/AdCreate2'));
const AdEdit = lazy(() => import('./dashboard/AdEdit'));
const PrizeDetailsAddOrganiser = lazy(() => import('./dashboard/PrizeDetailsAddOrganiser'));
const Faq = lazy(() => import('./dashboard/Faq'));
const PrivacyPolicy = lazy(() => import('./dashboard/PrivacyPolicy'));


class AppRoutes extends Component {
  render() {
    return (
      <Suspense fallback={<Spinner />}>
        <Switch >
          <Route path="/home" component={Admin} />
          <Route path="/admin" component={Dashboard} />
          <Route path="/partners" component={Partners} />
          <Route path="/organisers" component={Organisers} />
          <Route path="/admin_dashboard" component={AdminDashboard} />
          <Route path="/add_partners" component={AddPartners} />
          <Route path="/reg_partners" component={RegisterPartners} />
          <Route path="/view_partners" component={ViewPartners} />
          <Route path="/change_password" component={ChangePassword} />
          <Route path="/edit_partners" component={EditPartners} />
          <Route path="/partners_dashboard" component={PartnersDashboard} />
          <Route path="/partners_profile" component={PartnersProfile} />
          <Route path="/add_contest" component={AddContest} />
          <Route path="/add_contesttwo" component={AddContestTwo} />
          <Route path="/view_contest" component={ViewContest} />
          <Route path="/view_contesttwo" component={ViewContestTwo} />
          <Route path="/edit_contest" component={EditContest} />
          <Route path="/edit_contesttwo" component={EditContestTwo} />
          <Route path="/add_contest_partner" component={AddContestPartner} />
          <Route path="/add_contesttwo_partner" component={AddContestTwoPartner} />
          <Route path="/view_contest_partner" component={ViewContestPartner} />
          <Route path="/view_contesttwo_partner" component={ViewContestTwoPartner} />
          <Route path="/edit_contest_partner" component={EditContestPartner} />
          <Route path="/edit_contesttwo_partner" component={EditContestTwoPartner} />
          <Route path="/change_password_partner" component={ChangePasswordPartner} />
          <Route path="/organiser_dashboard" component={OrganiserDashboard} />
          <Route path="/contest_profile" component={ContestProfile} />
          <Route path="/prize_details" component={PrizeDetails} />
          <Route path="/change_password_contest" component={ChangePasswordContest} />
          <Route path="/payment_management" component={PaymentManagement} />
          <Route path="/add_contest_from_list" component={AddContestFromList} />
          <Route path="/view_contest_from_list" component={ViewContestFromList} />
          <Route path="/edit_contest_from_list" component={EditContestFromList} />
          <Route path="/prize_details_admin" component={PrizeDetailsAdmin} />
          <Route path="/prize_details_two_admin" component={PrizeDetailsTwoAdmin} />
          <Route path="/add_contest_from_list_partner" component={AddContestFromListPartner} />
          <Route path="/view_contest_from_list_partner" component={ViewContestFromListPartner} />
          <Route path="/prize_details_partner" component={PrizeDetailsPartner} />
          <Route path="/view_contest_organiser" component={ViewContestOrganiser} />
          <Route path="/prize_details_organiser" component={PrizeDetailsOrganiser} />
          <Route path="/edit_contest_from_list_organiser" component={EditContestFromListOrganiser} />
          <Route path="/prize_details_two_organiser" component={PrizeDetailsTwoOrganiser} />
          <Route path="/add_contest_from_list_organiser" component={AddContestFromListOrganiser} />
          <Route path="/add_contest_two_organiser" component={AddContestTwoOrganiser} />
          <Route path="/contest_register" component={ContestRegister} />
          <Route path="/contact_us" component={ContactUs} />
          <Route path="/organisers_forgot_password" component={OrganisersForgotPassword} />
          <Route path="/partner_forgot_password" component={PartnerForgotPassword} />
          <Route path="/ad_management" component={AdManagement} />
          <Route path="/ad_create" component={AdCreate} />
          <Route path="/ad_edit" component={AdEdit} />
          <Route path="/ad_management2" component={AdManagement2} />
          <Route path="/ad_create2" component={AdCreate2} />
          <Route path="/prize_details_add_organiser" component={PrizeDetailsAddOrganiser} />
          <Route path="/faq" component={Faq} />
          <Route path="/privacy_policy" component={PrivacyPolicy} />
          
          <Redirect to="/home" />
        </Switch>
      </Suspense>
    );
  }
}

export default AppRoutes;