import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./App.scss";
import AppRoutes from "./AppRoutes";
import Navbar from "./shared/Navbar";
import Sidebar from "./shared/Sidebar";
import SettingsPanel from "./shared/SettingsPanel";
import Footer from "./shared/Footer";
import { withTranslation } from "react-i18next";
import { AsyncStorage } from "AsyncStorage";
import NavbarPlain from "./shared/NavbarPlain";

class App extends Component {
  state = {
    name: "",
    show_component: true,
    show_sidebar: false,
  };
  componentDidMount() {
    this.onRouteChanged();
  }

  render() {
    let navbarComponent = !this.state.isFullPageLayout ? <Navbar /> : "";
    let sidebarComponent = !this.state.isFullPageLayout ? (
      <Sidebar name={this.state.name} />
    ) : (
      ""
    );
    let SettingsPanelComponent = !this.state.isFullPageLayout ? (
      <SettingsPanel />
    ) : (
      ""
    );
    let footerComponent = !this.state.isFullPageLayout ? <Footer /> : "";
    return (
      <div className="container-scroller">
        {this.state.show_component ? navbarComponent : <NavbarPlain />}
        <div className="page-body-wrapper">
          {this.state.show_component && sidebarComponent}
          <div style={{ padding: 16, width: "100%", marginTop: 64 }}>
            <AppRoutes />
          </div>
          {/* </div> */}
        </div>
        <Footer />
      </div>
    );
  }

  componentDidMount() {
    console.log("DATA1", this.props.location?.pathname);
    if (
      ["/home", "/faq", "/contact_us", "/partners", "/organisers"].indexOf(
        this.props.location?.pathname
      ) !== -1
    ) {
      //show component
      this.setState({ show_component: true });
    } else {
      //hide component

      this.setState({
        show_component: false,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      console.log("DATA1", this.props.location?.pathname);
      if (
        ["/home", "/faq", "/contact_us", "/partners", "/organisers"].indexOf(
          this.props.location?.pathname
        ) !== -1
      ) {
        //show component
        this.setState({ show_component: true });
      } else {
        //hide component

        this.setState({
          show_component: false,
        });
      }
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    window.scrollTo(0, 0);
    const fullPageLayoutRoutes = ["/add-category"];
    for (let i = 0; i < fullPageLayoutRoutes.length; i++) {
      if (this.props.location.pathname === fullPageLayoutRoutes[i]) {
        this.setState({
          isFullPageLayout: true,
        });
        document
          .querySelector(".page-body-wrapper")
          .classList.add("full-page-wrapper");
        break;
      } else {
        this.setState({ isFullPageLayout: false });
        document
          .querySelector(".page-body-wrapper")
          .classList.remove("full-page-wrapper");
      }
    }
  }
}

export default withTranslation()(withRouter(App));
